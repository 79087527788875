import React, { useEffect, useState } from "react";
import {
  NODE_LI,
  NODE_PARAGRAPH,
  NODE_UL,
  MARK_CODE,
} from "storyblok-rich-text-react-renderer";
import { render } from "storyblok-rich-text-react-renderer-ts";

const NeedAssiRichText = (props) => {
  const [Description, setDescription] = useState("");

  useEffect(() => {
    setDescription(props.Description);
  }, [props.Description]);
  return render(Description, {
    nodeResolvers: {
      [NODE_LI]: (Description) => (
        <>
          <li>{Description}</li>
        </>
      ),
      [NODE_UL]: (Description) => <ul className="media-list">{Description}</ul>,
      [NODE_PARAGRAPH]: (Description) => (
        <p>{Description} </p>
      ),
    },
    markResolvers: {
      [MARK_CODE]: (Description) => (
        <span className="text-orange">
          {Description}
        </span>
      ),
    },
  });
};
export default NeedAssiRichText;
