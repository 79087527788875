import React from "react";
import NeedAssiRichText from "./NeedAssiRichText";
import RichText from "./RichText";
export default function NeedAssistance(props) {
  return (
    <div>
      {" "}
      <div className="state-container auto-assistance">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4 text-center">
              <img src={props?.data?.content?.img} className="img-fluid" />
            </div>
            <div className="col-sm-8">
              <div className="w-100">
                <h1 className="noto-sans-font text-left">
                  <strong>{props?.data?.content?.Heading}</strong>
                </h1>
                {/* {props?.data?.content?.Paragraph?.content?.map((item) => {
                  return (
                    <>
                      <RichText Description={props?.data?.content?.Paragraph?} />
                    </>
                  );
                })} */}
                <>
                  <RichText Description={props?.data?.content?.Paragraph} />
                </>
                {/* <p>
                  If you’ve{" "}
                  <span className="text-orange">
                    received an insurance notice from the Secretary of State to
                    prove coverage
                  </span>{" "}
                  or are looking for auto insurance coverage to help you’re
                  situation, you can reach out to us with the contact
                  information below so we can make sure you have the right
                  coverage to drive legally.{" "}
                </p> */}
                <div className="w-75 sub-contact">
                  <div className="row no-gutters">
                    <div className="col-sm-4">
                      <img
                        alt={props?.data?.content?.Basic[1]?.icon_image?.alt}
                        src={props?.data?.content?.Basic[1]?.icon_image?.filename}
                        className="icon"
                      />
                      <p>
                        <RichText
                          Description={
                            props?.data?.content?.Basic[1]?.title_text
                          }
                        />

                        <span >
                          <b>
                            <a className="text-orange" href={'tel:'+ props?.data?.content?.Basic[1]?.link_text}>
                            <RichText
                              Description={
                                props?.data?.content?.Basic[1]?.link_text
                              }
                            />
                            </a>
                          </b>
                        </span>
                      </p>
                    </div>
                    <div className="col-sm-2 d-flex justify-content-center align-items-center">
                      <p>Or</p>
                    </div>
                    <div className="col-sm-6">
                      <img
                        alt={props?.data?.content?.Basic[0]?.Image?.alt}
                        src={props?.data?.content?.Basic[0]?.Image?.filename}
                        className="icon"
                      />
                      {/* <p className="btn btn-outline-secondary"> */}

                      <a
                        href={"mailto: agent@insureonline.com"}
                        // target={
                        //   props?.bannnerData[0]?.content?.buttons[0]?.link_target
                        // }
                      >
                        <div className="btn btn-outline-secondary mb-0">
                          <NeedAssiRichText
                            Description={
                              props?.data?.content?.Basic[0]?.Description
                            }
                          />
                        </div>

                        {/* <span className="text-orange">Free Auto Quote</span> */}
                        {/* </p> */}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
