import React, { useEffect, useState } from "react";
// import "../Assets/newCityAssets/assets/custom-styles/state-css.scss";
import HeroSection from "../components/States//HeroSection";
import SEO from "../components/seo";
import GetQuickSection from "../components/States//GetQuickSection";
import PlantoMove from "../components/States//PlantoMove";
import RequirementSection from "../components/States//RequirementSection";
import GetcarInsurance from "../components/States//GetcarInsurance";
import PolicesSection from "../components/States//PoliciesSection";
import AboutAgenst from "../components/States//AboutAgents";
// import ListofCities from "../components/States//ListsofCities";
import ArticleSection from "../components/States//ArticleSection";
import FooterSection from "../components/basic/FooterSection";
import DiscountsSection from "../components/States/DiscountsSection";
import NeedAssistance from "../components/States/NeedAssistance";
import LogoSection from "../components/basic/LogoSection";
import icon from '../Assets/images/favicon.ico'
import { Helmet } from "react-helmet"
const storyblokAPI = process.env.GATSBY_APP_STORYBLOK_TOKEN;
const storyblokVersion = process.env.GATSBY_APP_STORYBLOK_VERSION;

export default function AutoInsuranceIllinois() {
  const [storyLists, setStories] = useState([]);
  const [heroSection, setHeroSection] = useState([]);
  const [getQuick, setGetQuick] = useState([]);
  const [aboutAgent, setAboutAgent] = useState([]);
  const [discountSection, setDiscountSection] = useState([]);
  const [getacarInsurance, setGetaCarInsurance] = useState([]);
  // const [listofCities, setListOfCities] = useState([]);
  const [needAssistance, setNeedAssistance] = useState([]);
  const [plantoMove, setPlantoMove] = useState([]);
  const [policiesSection, setPoliciesSection] = useState([]);
  const [requirementSection, setRequirementSection] = useState([]);
  const [bannnerData, setBannerData] = useState([]);
  const [metaData, setMetaData] = useState([]);
  // const [aboutUs, setAboutUS] = useState([]);
  // const [isPageloaded, setisPageloaded] = useState(true);
  // // const url = props.url;
  // const state = props.state;
  // const city = props.city;

  // const dynamic_state = state === "IL" ? "illinois" : "indiana";

  useEffect(() => {
    const api =
      "https://api.storyblok.com/v2/cdn/stories?starts_with=insureonline/state-pages/illinois/&per_page=100&page=1&token=" +
      storyblokAPI +"&version=" + storyblokVersion;

    fetch(api)
      .then((response) => response.json())
      .then((json) => {
        setStories(json.stories);
        // setisPageloaded(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);


  const banner =
    "https://api.storyblok.com/v2/cdn/stories?starts_with=insureonline/city-pages/common/get-quick-link-on-banner&token=" +
    storyblokAPI +
    "&version=" +
    storyblokVersion;
  useEffect(() => {
    fetch(banner)
      .then((response) => response.json())
      .then((json) => {
        setBannerData(json.stories);
        // setisPageloaded(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);


  useEffect(() => {
    let heroSection = storyLists?.filter(heroFindBySlug);
    function heroFindBySlug(arg) {
      if (arg?.full_slug === "insureonline/state-pages/illinois/hero-section") {
        return arg;
      }
    }
    setHeroSection(heroSection[0]);

    let getQuick = storyLists?.filter(getQuickFindBySlug);
    function getQuickFindBySlug(arg) {
      if (
        arg?.full_slug === "insureonline/state-pages/illinois/get-quick-section"
      ) {
        return arg;
      }
    }
    setGetQuick(getQuick[0]);

    let aboutAgent = storyLists?.filter(aboutFindBySlug);
    function aboutFindBySlug(arg) {
      if (arg?.full_slug === "insureonline/state-pages/illinois/about-agent") {
        return arg;
      }
    }
    setAboutAgent(aboutAgent[0]);

    let discountSection = storyLists?.filter(discountFindbySlug);

    function discountFindbySlug(arg) {
      if (
        arg?.full_slug ===
        "insureonline/state-pages/illinois/available-car-discounts"
      ) {
        return arg;
      }
    }
    setDiscountSection(discountSection[0]);

    let getacarInsurance = storyLists?.filter(getCarFindbySlug);

    function getCarFindbySlug(arg) {
      if (
        arg?.full_slug ===
        "insureonline/state-pages/illinois/get-a-car-insurance"
      ) {
        return arg;
      }
    }
    setGetaCarInsurance(getacarInsurance[0]);

    // let listofCities = storyLists?.filter(listofCarFindbySlug);

    // function listofCarFindbySlug(arg) {
    //   if (arg?.full_slug === "") {
    //     return arg;
    //   }
    // }
    // setListOfCities(listofCities[0]);

    let needAssistance = storyLists?.filter(needToFindbySlug);

    function needToFindbySlug(arg) {
      if (
        arg?.full_slug ===
        "insureonline/state-pages/illinois/need-auto-assistance"
      ) {
        return arg;
      }
    }
    setNeedAssistance(needAssistance[0]);

    let plantoMove = storyLists?.filter(planToFindbySlug);

    function planToFindbySlug(arg) {
      if (arg?.full_slug === "insureonline/state-pages/illinois/driver-plan") {
        return arg;
      }
    }
    setPlantoMove(plantoMove[0]);

    let policiesSection = storyLists?.filter(policiesToFindbySlug);

    function policiesToFindbySlug(arg) {
      if (
        arg?.full_slug === "insureonline/state-pages/illinois/policies-section"
      ) {
        return arg;
      }
    }
    setPoliciesSection(policiesSection[0]);

    let requirementSection = storyLists?.filter(reqToFindbySlug);

    function reqToFindbySlug(arg) {
      if (
        arg?.full_slug ===
        "insureonline/state-pages/illinois/requirement-section"
      ) {
        return arg;
      }
    }
    setRequirementSection(requirementSection[0]);

    let metaData = storyLists?.filter(metaDataFindbySlug);
    function metaDataFindbySlug(arg) {
      if (
        arg?.full_slug === "insureonline/state-pages/illinois/meta-data") {
        return arg;
      }
    }
    setMetaData(metaData[0]);

  }, [storyLists]);

  return (
    <div className="main-wrapper">
      <Helmet>
      <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={icon}
        />
        <script src="https://reviewsonmywebsite.com/js/v2/embed.js?id=c338ae55b38e487f19ae" type="text/javascript"></script>
      </Helmet>
      {/* {metaData && (
          <SEO
            data={metaData}
            title={metaData?.content?.Meta?.title}
            description={metaData?.content?.Meta?.description}
            keyword={metaData?.content?.keywords}
          />
        )} */}
      <SEO title="Illinois Auto Insurance | Get Car insurance Quotes | Auto Insure Online" description="Get a free Illinois Auto insurance quote Online. Insure Online offers affordable car insurance quotes, SR-22 in Illinois. Get Personalized coverage options, Discounts & More." keyword="Best auto insurance Illinois , cheap car insurance quotes online, sr22 insurance Illinois, Automobile insurance"/>
      <LogoSection  bannnerData={bannnerData} />
      <HeroSection data={heroSection} bannnerData={bannnerData} />
      <GetQuickSection data={getQuick} />
      <PlantoMove data={plantoMove} bannnerData={bannnerData} />
      <RequirementSection data={requirementSection} />
      <DiscountsSection data={discountSection} bannnerData={bannnerData} />
      <GetcarInsurance data={getacarInsurance} />
      <NeedAssistance data={needAssistance} bannnerData={bannnerData} />
      <PolicesSection data={policiesSection} />
      <AboutAgenst data={aboutAgent} />
      {/* <ListofCities data={listofCities}/> */}
      <ArticleSection state='illinois'/>
      <FooterSection />
    </div>
  );
}
